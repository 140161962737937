import React, {useContext} from "react";
import {testimonies} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";

export default function Testimony() {
  const {isDark} = useContext(StyleContext);
  if (testimonies.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">{testimonies.title || "Testimonies"}</h1>
              <div className="experience-cards-div">
                {testimonies.testimony.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        // company: card?.designation?.split("@")[1],
                        desc: card.feedback,
                        date: card?.designation,
                        companylogo: card.image,
                        role: card?.name?.split("@")[0],
                        // descBullets: card.descBullets
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;

}
