import React from "react";
import "./App.scss";
import Main from "./containers/Main";

function App() {
  
  function collectVisitorData() {
    let ipAddress;

    // Fetch the visitor's IP address from ipify.org
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        ipAddress = data.ip;

        const currentURL = window.location.href;

       var raw = JSON.stringify({
          "ipAddress": ipAddress,
          "device": navigator.userAgent,
          "currentURL": currentURL
        });

        var requestOptions = {
          method: 'POST',
          body: raw,
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow'
        };

        fetch("https://api.anilgulati.com/api/portfolio/log-visitor", requestOptions)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  collectVisitorData();

  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
