/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1500 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Anil Gulati",
  title: "Hey, I'm Anil",
  subTitle: emoji(
    "I am a dedicated Full Stack Software Developer 🚀 with a strong background in building web and mobile applications. I specialize in Java, Spring boot, microservices, hibernate, JavaScript, React.js, Node.js, Anugular and React Native, alongside a range of other cutting-edge technologies and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1VOsuUdMV9ZzujV6lvitMlnTkp_Go_37u/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/anilfromdit",
  linkedin: "https://www.linkedin.com/in/anilfromdit/",
  gmail: "anilfromdit@gmail.com",
  // medium: "https://medium.com/@anilfromdit",
  // stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "Full Stack Developer with a focus on Java, JavaScript, AWS, and Azure",
  skills: [
    emoji("⚡Java, JavaScript, C, C++, Python, Dart, Swift (beginner), HTML, CSS"),
    emoji("⚡ Spring Boot, React JS, React Native, Next.js, REST API, Hibernate, Web-Extensions, Express.js, Node.js"),
    emoji("⚡SQL, MongoDB, AWS RDS, (MySQL, PostgreSQL)"),
    emoji("⚡AWS, Google Cloud, Azure"),
    emoji("⚡JUnit, Git, Maven, Docker, Kubernetes, Jenkins, Ansible"),
    emoji("⚡Leadership, Communication, Problem Analysis, MS Excel"),
    emoji("⚡Research, Leadership Development, Problem - solving, SRE, Linux"),
    emoji("⚡Time Management, Agile, Strategy, Project Management"),
    emoji("⚡Microservices, Power BI, Data Analysis, Data Visualization, Risk Analysis "),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "html",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "ReactJs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Angular",
      fontAwesomeClassname: "fab fa-angular"
    },
    {
      skillName: "NextJs",
      fontAwesomeClassname: "fab fa-neos"
    },
    {
      skillName: "NodeJs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "ElectronJs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "GIT",
      fontAwesomeClassname: "fab fa-git-alt"
    },
    {
      skillName: "Ubutnu",
      fontAwesomeClassname: "fab fa-ubuntu"
    },
    {
      skillName: "Azure",
      fontAwesomeClassname: "fas fa-cloud"
    },
    {
      skillName: "C/C++",
      fontAwesomeClassname: "fas fa-copyright"
    },
    {
      skillName: "Jira",
      fontAwesomeClassname: "fab fa-jira"
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "fab fa-figma"
    },
    {
      skillName: "No-SQL Database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "Spring Boot",
      fontAwesomeClassname: "fab fa-java"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "DIT University",
      logo: require("./assets/images/dit_logo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "August 2020 - May 2024",
      desc: "DIT University, A grade Accredited by NAAC, offers many courses specializing in Engineering, Law, Management, and many more for both UG, PG and Phd programs.",
      descBullets: ["Specialization in Full Stack & DevOps",
        `Maintained a strong academic record with a CGPA of 8.7.`,
        `Engaged in the Computer Science Club, actively participating in coding community activities.`,
        `Managed and completed numerous projects, highlighting the ability to handle complex tasks, meet deadlines, and deliver high-quality results.`

      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend", //Insert stack or technology you have experience in
      progressPercentage: "99%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "99%"
    },
    {
      Stack: "Database",
      progressPercentage: "99%"
    },
    {
      Stack: "Cloud Services",
      progressPercentage: "99%"
    },
    {
      Stack: "Version Control",
      progressPercentage: "99%"
    },
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, // Set it to true to show workExperiences Section
  experience: [
    {
      role: "Associate Software Engineer",
      company: "Yamaha Motor Solution India Pvt. Ltd.",
      companylogo: require("./assets/images/Yamaha.jpg"),
      date: "Jan 2024 – Present",
      desc: "As an Associate Software Engineer (ASE) at Yamaha Motor Solutions Ltd. India, I am responsible for developing and maintaining software applications, ensuring high performance and responsiveness to requests from the front-end. My key responsibilities include:",
      descBullets: [
        "Spearheading the development and optimization of critical modules using Spring Boot within the Yamaha Dealer Management System (YDMS).",
        "Developed Local Part Master module, which facilitates third-party part and accessory management for dealers.",
        "Developed and implemented the Auto Purchase Order module, automating part orders based on sales trends and demand analysis. Enhancing stock management efficiency and reducing manual intervention.",
        "Implemented Spare Parts Profitability Report module, providing insights into parts profitability to support strategic decision-making.",
      ]
    },
    {
      role: "SDE-1",
      company: "Onelab Ventures",
      companylogo: require("./assets/images/onelab_logo.png"),
      date: "Aug 2022 – Dec 2023",
      desc: "I've had the privilege of working with esteemed clients like RFIS (Reliance Foundation), Gatiavn, and Popkorn Fintech during my tenure as an SDE-1 at Onelab Ventures. My responsibilities included:",
      descBullets: [
        "Designing and developing a vehicle service booking website backend using Spring Boot.",
        "Creating an e-learning Progressive Web App (PWA) using Strapi to ensure accessibility across various devices.",
        "Deploying and managing multiple applications on AWS EC2 to guarantee high availability.",
        "Developing the vehicle service booking website backend with Spring Boot.",
        "Leading the development of production-level React Native applications."
      ]
    },
    {
      role: "MERN Stack Intern",
      company: "Prepseed",
      companylogo: require("./assets/images/prepseed_logo.png"),
      date: "May 2022 – Aug 2022",
      desc: "As a MERN Stack Intern at Prepseed, I took a leadership role in our intern team, spearheading front-end development efforts to enhance platform analytics and streamline meetings and webinars through Zoom SDK integration.",
      descBullets: [
        "Led the intern team to successfully implement the parent component module, resulting in notable improvements to platform performance.",
        "Contributed significantly to security enhancements, including the development of an email login detection feature.",
        "Collaborated closely with the design team to ensure a seamless and user-friendly interface.",
        "Participated actively in code reviews and mentorship of fellow interns to maintain code quality and knowledge sharing."
      ]
    },
    // {
    //   role: "Technical Head",
    //   company: "DITU ACM Student Chapter",
    //   companylogo: require("./assets/images/ditu_acm.png"),
    //   date: "Sept 2021 –  OCT 2023",
    //   desc: "As Technical Head, I led a passionate team in organizing technical events, hackathons, and workshops, fostering a culture of collaboration, learning, and growth within the ACM Student Chapter.",
    //   descBullets: [
    //     "Led a dedicated team in planning and executing various technical events and hackathons, fostering a vibrant tech community.",
    //     "Championed collaborative learning and problem-solving initiatives within the student body, promoting knowledge exchange.",
    //     "Significantly contributed to the expansion and visibility of the ACM Student Chapter within the university."
    //   ]
    // }
  ]
};


/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "SOME PERSONAL PROJECTS",
  projects: [
    {
      image: require("./assets/images/excess_labs.png"),
      projectName: "Excess Labs",
      projectDesc: "A B.A.A.S Project",
      footerLink: [
        {
          name: "API Documentation",
          url: "https://documenter.getpostman.com/view/24493713/2s93Y5PzMU"
        },
        {
          name: "Presentation",
          url: "https://prezi.com/p/uib_khnasenk/?present=1"
        },
        {
          name: "Github",
          url: "https://github.com/anilfromdit/Excess-Labs"
        },
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/portfolio_v3.png"),
      projectName: "Portfolio Website",
      projectDesc: "Portfolio Webiste",
      footerLink: [
        {
          name: "V1 - Github",
          url: "https://github.com/anilfromdit/old-anilfromdit.github.io"
        },
        {
          name: "V2 - Github",
          url: "https://github.com/anilfromdit/Portfolio-Website"
        },
        {
          name: "V3 - Live",
          url: "/"
        },
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/mmp.webp"),
      projectName: "Mark Me Present - Pro",
      projectDesc: "An App that aims to automate the attendance taking system",
      footerLink: [
        {
          name: "Download V1.1.4",
          url: "https://drive.google.com/file/d/1QYxZqp_vSioxWqurx-IdGC-YjPOXG31d/view?usp=sharing"
        },
        {
          name: "Github",
          url: "https://github.com/anilfromdit/Mark-Me-Present-Pro--MMP"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/wedo.webp"),
      projectName: "WE DO",
      projectDesc: "An Android App Which Aims to Prevent Dating Frauds on Social Media Platforms",
      footerLink: [
        {
          name: "Download V1.0.0",
          url: "https://drive.google.com/file/d/1QwpFm-vEjlqABq_7ufV0Qb_hBsWa2not/view?usp=sharing"
        },
        {
          name: "Github",
          url: "https://github.com/anilfromdit/WE-DO"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/emart.png"),
      projectName: "E-mart",
      projectDesc: "E Commerce Website Using MERN Stack",
      footerLink: [
        {
          name: "Github",
          url: "https://github.com/anilfromdit/E-Mart"
        }
      ]
    },
    {
      image: require("./assets/images/let_me_watch_anime.png"),
      projectName: "Let Me Watch Anime",
      projectDesc: "A browser extension orchestrating seamless automatic transitions to the subsequent episode's video content, sparing users the burden of manual intervention.",
      footerLink: [
        {
          name: "Github",
          url: "https://github.com/anilfromdit/Let-Me-Watch-Anime"
        }
        //  you can add extra buttons here.
      ]
    },

  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Oracle Certified Associate, Java SE 8 Programmer",
      subtitle:
        `Oracle Certified Associate, Java SE 8 Programmer`,
      image: require("./assets/images/OCA.png"),
      imageAlt: "Anil Gulati Oracle Java SE 8 Certification ",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://catalog-education.oracle.com/pls/certview/sharebadge?id=07BAB06DD77F2958AB2D9531C3839348DCD162F7851465D8C6BDEC28FE192489"
        }
      ]
    },
    {
      title: "Quizopedia 3rd Rank",
      subtitle:
        `Secured 3rd Position in "Quizopedia" organized by Classroom Study`,
      image: require("./assets/images/Quizopedia.png"),
      imageAlt: "Quizopedia 3rd rank certificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1kK0IJ1O4wKV89NSQ1dl_EUxbM8RPaMGY/view?usp=sharing"
        }
      ]
    },
    {
      title: "Salesforce Certified Developer",
      subtitle:
        "Completed Salesforce Certification, mastering modules in Salesforce Fundamentals, Organizational Setup, Apex Development, Lightning Web Components (LWC), and more.",
      image: require("./assets/images/salesforce.png"),
      imageAlt: "Salesforce certificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1DUoIM1iKDvQ-k-ikF6uZzhqMGQM_TYn6/view?usp=sharing"
        },
        {
          name: 'Verify Certificate',
          url: `https://smartinternz.com/internships/salesforce_certificates/16c66d09450b3dfe2bfe95657b562fa3`
        }
      ]
    },
    {
      title: "Front-End Web Development with React Certification",
      subtitle:
        `Authorized by The Hong Kong University of Science and Technology through Coursera - October 29, 2022"`,
      image: require("./assets/images/react_certificate.png"),
      imageAlt: "React certificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1guvHaHqglbV5hvqgwSVmOG-5ad_YfOcC/view?usp=sharing"
        },
        {
          name: 'Verify Certificate',
          url: `https://coursera.org/verif y/E5CKVJL4R5K3`
        }
      ]
    },
    {
      title: "Spring Boot 1.0 Essential Training Certification",
      subtitle:
        `Certified in Essential Spring Boot Training by LinkedIn Learning`,
      image: require("./assets/images/sprinboot_certificate.png"),
      imageAlt: "SpringBoot certificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1xNkZ63muDsWAsCcTlVaxHZs_xchCaFWi/view?usp=sharing"
        },
      ]
    },

  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  display: true // Set false to hide this section, defaults to true
};


const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-9256391000",
  email_address: "anilfromdit@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

const testimonies = {
  title: "Testimonies",
  testimony: [
    {
      image: require("./assets/images/chaitali.png"),
      name: "Chaitali Sawant",
      designation: "Senior Product Manager @ Kalayani Studio",
      feedback:"I would highly recommend Anil for any technology development project in React, Reactnative and Java. Despite his young age and ongoing studies, he demonstrates a level of skill and professionalism that's on par with seasoned professionals. His ability to tackle challenges head-on and provide foolproof solutions is truly impressive. I have no doubt that he will continue to excel in his career.", 
      footerLink: [
        
      ]
    },
    {
      image: require("./assets/images/lavanya.png"),
      name: "Lavanya Singh",
      designation: "Product Owner @ Orange League Ventures",
      feedback: "I would highly recommend Anil for the role of SDE. I have seen Anil's work on Gativan, Machli and Kisangrow. He has been the Backbone of every project assigned to him. His technical capabilities are quite good. He is reliable, efficient and quick on any task assigned to him. He has been an asset to the organization.",
      footerLink: [
     
      ]
    },
    {
      image: require("./assets/images/rohit.png"),
      name: "Rohith Aswarth",
      designation: "Former CTO @ ActivetLife (AL) Services",
      feedback: "I wholeheartedly recommend Mr. Anil Gulati for his outstanding skills in development and unwavering dedication. Anil's innovative thinking, remarkable communication abilities, and meticulous attention to detail set him apart. His commitment to excellence makes him a rare asset in the industry, and I confidently endorse him for any development role or project. Anil's contributions are sure to elevate the success of any team or organization he joins.",
      footerLink: [
      
      ]
    },
  

  ],
  display: true // Set false to hide this section, defaults to true
};


export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  contactInfo,
  twitterDetails,
  isHireable,
  testimonies
};
